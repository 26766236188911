// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-our-approach-js": () => import("./../src/pages/about/our-approach.js" /* webpackChunkName: "component---src-pages-about-our-approach-js" */),
  "component---src-pages-about-testimonials-case-studies-js": () => import("./../src/pages/about/testimonials-case-studies.js" /* webpackChunkName: "component---src-pages-about-testimonials-case-studies-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-hr-startup-js": () => import("./../src/pages/hr-startup.js" /* webpackChunkName: "component---src-pages-hr-startup-js" */),
  "component---src-pages-privacy-index-js": () => import("./../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-training-online-training-js": () => import("./../src/pages/training/online-training.js" /* webpackChunkName: "component---src-pages-training-online-training-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-about-partners-js": () => import("./../src/templates/about/partners.js" /* webpackChunkName: "component---src-templates-about-partners-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-compliance-affirmative-action-js": () => import("./../src/templates/compliance/affirmative-action.js" /* webpackChunkName: "component---src-templates-compliance-affirmative-action-js" */),
  "component---src-templates-compliance-assessment-js": () => import("./../src/templates/compliance/assessment.js" /* webpackChunkName: "component---src-templates-compliance-assessment-js" */),
  "component---src-templates-compliance-diversity-programs-js": () => import("./../src/templates/compliance/diversity-programs.js" /* webpackChunkName: "component---src-templates-compliance-diversity-programs-js" */),
  "component---src-templates-compliance-page-js": () => import("./../src/templates/compliance-page.js" /* webpackChunkName: "component---src-templates-compliance-page-js" */),
  "component---src-templates-compliance-policy-development-js": () => import("./../src/templates/compliance/policy-development.js" /* webpackChunkName: "component---src-templates-compliance-policy-development-js" */),
  "component---src-templates-home-page-js": () => import("./../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-newsletter-post-js": () => import("./../src/templates/newsletter-post.js" /* webpackChunkName: "component---src-templates-newsletter-post-js" */),
  "component---src-templates-services-compensation-benefits-js": () => import("./../src/templates/services/compensation-benefits.js" /* webpackChunkName: "component---src-templates-services-compensation-benefits-js" */),
  "component---src-templates-services-employee-handbooks-js": () => import("./../src/templates/services/employee-handbooks.js" /* webpackChunkName: "component---src-templates-services-employee-handbooks-js" */),
  "component---src-templates-services-employee-relations-js": () => import("./../src/templates/services/employee-relations.js" /* webpackChunkName: "component---src-templates-services-employee-relations-js" */),
  "component---src-templates-services-investigations-js": () => import("./../src/templates/services/investigations.js" /* webpackChunkName: "component---src-templates-services-investigations-js" */),
  "component---src-templates-services-job-descriptions-js": () => import("./../src/templates/services/job-descriptions.js" /* webpackChunkName: "component---src-templates-services-job-descriptions-js" */),
  "component---src-templates-services-page-js": () => import("./../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */),
  "component---src-templates-services-recruiting-staffing-js": () => import("./../src/templates/services/recruiting-staffing.js" /* webpackChunkName: "component---src-templates-services-recruiting-staffing-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-training-additional-training-js": () => import("./../src/templates/training/additional-training.js" /* webpackChunkName: "component---src-templates-training-additional-training-js" */),
  "component---src-templates-training-mandated-training-js": () => import("./../src/templates/training/mandated-training.js" /* webpackChunkName: "component---src-templates-training-mandated-training-js" */),
  "component---src-templates-training-page-js": () => import("./../src/templates/training-page.js" /* webpackChunkName: "component---src-templates-training-page-js" */)
}

